import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { UkTermsPageQuery } from '../../../generated/types';
import { HomepageUkTemplate } from '../../components/templates/HomepageUkTemplate';
import { CookieTermsContent } from '../../modules/legal/CookiesTermsContent';

type PpUkTermsPage = PageProps<UkTermsPageQuery>;

function TermsPageUk({ data: { content } }: PpUkTermsPage): React.ReactElement {
  return (
    <HomepageUkTemplate seo={content?.frontmatter?.seo}>
      <CookieTermsContent content={content} sx={{ paddingY: 4 }} />
    </HomepageUkTemplate>
  );
}

export const query = graphql`
  query UkTermsPage {
    content: markdownRemark(frontmatter: { layout: { eq: "terms" }, locale: { eq: "en" } }) {
      rawMarkdownBody
      frontmatter {
        locale
        seo {
          title
          description
          image
        }
        title
        date(formatString: "DD MM YYYY")
        termsDescription
      }
    }
  }
`;

export default TermsPageUk;
